// @import '../../styles/custom-bootstrap.scss';

p {
  font-size: 12px;
}

label {
  font-size: 12px;
  font-family: 'Clan Pro Medium';
  color: #666666;
}

input {
  font-size: 12px !important;
}
.title {
  font-size: 14px;
  font-family: 'Clan Pro Bold';
}

.status-btn {
  width: 100px;
  font-size: 12px;
}
#error-svg path {
  fill: #e2021a
}
.toast-label {
  font-size: 14px;
}