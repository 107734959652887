#table-spinner {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}


#container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding-top: 60px;
}

#header {
  color: white;
  text-align: center;
  flex-shrink: 0;
}

#footer {
  color: white;
  text-align: center;
  flex-shrink: 0;
  padding-top: 10px;
}

#content {
  flex-grow: 1;
  overflow-y: auto;
}