.modal-content {
  border-radius: 0px;
  .modal-header {
    border-bottom: 0px;
    padding-bottom: 0px;
    font-size: 16px;
  }
  .modal-title {
    font-family: 'Clan Pro Medium';
    font-size: 18px;
  }
  .modal-footer {
    padding-top: 0px;
    border-top: 0px;
  }
}
