@import 'react-toastify/dist/ReactToastify.css';

.Toastify__toast {
  padding: 16px;
  border-radius: 0px;
}

.Toastify__toast-theme--light {
  color: #212529
}

.Toastify__close-button--light {
  opacity: 1;
}

.Toastify__toast-container--top-right {
  right: 4rem;
}

.Toastify__zoom-enter, .Toastify__zoom-exit {
  animation-duration: 0.1s !important;
}