@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$primary: #ffed00;
$secondary: #E3E3E3;
$success: #4aad40;
$info: #007bff;
$warning: #f5802a;
$danger: #e2021a;
$light: #f7f7f7;
$dark: #212529;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

$kärcherYellow: #ffed00;
$kärcherYellow-100: #fffbcc;
$kärcherYellow-200: #fff899;
$kärcherYellow-300: #fff466;
$kärcherYellow-400: #fff133;
$kärcherYellow-500: #ffed00;
$kärcherYellow-600: #ccbe00;
$kärcherYellow-700: #998e00;
$kärcherYellow-800: #665f00;
$kärcherYellow-900: #332f00;

$gray-100: #e5e5e5;
$gray-200: #cccccc;
$gray-300: #b3b3b3;
$gray-400: #999999;
$gray-500: #808080;
$gray-600: #666666;
$gray-700: #4d4d4d;
$gray-800: #333333;
$gray-900: #191919;



$font-size-base: 14px;


$border-color: $gray-400;
$border-radius: 0;


$component-active-bg: $primary;

// pagination
$pagination-active-bg: $primary;
$pagination-active-color: #000;
$pagination-color: #000;
$pagination-border-width: 0px;
$pagination-font-size: 12px;

@import './form.scss';
@import './typography.scss';
@import './spacer.scss';
@import './form-control.scss';
@import '~bootstrap/scss/bootstrap';
@import './button.scss';
