.user-table {
  overflow-y: auto;

  td {
    background-color: transparent !important;
    font-size: 12px;
    vertical-align: middle;

    .info-text {
      display: inline;
    }
  }

  thead tr {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th {
    background-color: transparent !important;
    font-size: 14px;
  }

  tr {
    border-color: transparent !important;
  }

  .badge {
    border-radius: 10px;
    padding: 5px 10px;
  }

}