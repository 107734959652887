#role-filter {
  .form-check-input {
    border-radius: 0px;
    margin: 0px;
    margin-right: 12px;
    width: 18px;
    height: 18px;
  }

  .form-check {
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .form-check-label {
    font-family: 'Clan Pro News';
  }
 
}